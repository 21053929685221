<!-- 报表展示 -->
<template>
  <div style="background-color: #ececec; padding: 5px">
    <a-row>
      <a-col :span="8">
        <a-card
          title="短息发送概况"
          :bordered="false"
          style="width: 95%; margin: 5px"
        >
          <a-row>
            <a-col :span="8">
              <a-statistic
                title="发送总量"
                :value="messageCountInfo.sum"
                style="margin-left: 20px"
              />
            </a-col>
            <a-col :span="8">
              <a-statistic
                title="同比"
                :value="messageCountInfo.pre"
                :precision="2"
                suffix="%"
                :value-style="{ color: '#3f8600' }"
              >
                <template #prefix>
                  <arrow-up-outlined />
                </template>
              </a-statistic>
            </a-col>
            <a-col :span="8">
              <a-statistic
                title="日均发送量"
                :value="messageCountInfo.avg"
                style="margin-right: 20px"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card
          title="错误预警概况"
          :bordered="false"
          style="width: 95%; margin: 5px"
        >
          <a-row>
            <a-col :span="8">
              <a-statistic
                title="错误总量"
                :value="messageCountInfo.sum"
                style="margin-left: 20px"
              />
            </a-col>
            <a-col :span="8">
              <a-statistic
                title="同比"
                :value="messageCountInfo.pre"
                :precision="2"
                suffix="%"
                :value-style="{ color: '#3f8600' }"
              >
                <template #prefix>
                  <arrow-up-outlined />
                </template>
              </a-statistic>
            </a-col>
            <a-col :span="8">
              <a-statistic
                title="日均错误量"
                :value="messageCountInfo.avg"
                style="margin-right: 20px"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card
          title="总发送概况"
          :bordered="false"
          style="width: 95%; margin: 5px"
        >
          <a-row>
            <a-col :span="8">
              <a-statistic
                title="发送总量"
                :value="messageCountInfo.sum"
                style="margin-left: 20px"
              />
            </a-col>
            <a-col :span="8">
              <a-statistic
                title="同比"
                :value="messageCountInfo.pre"
                :precision="2"
                suffix="%"
                :value-style="{ color: '#3f8600' }"
              >
                <template #prefix>
                  <arrow-up-outlined />
                </template>
              </a-statistic>
            </a-col>
            <a-col :span="8">
              <a-statistic
                title="日均发送量"
                :value="messageCountInfo.avg"
                style="margin-right: 20px"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="16">
        <a-card
          title="发送月度趋势分析"
          :bordered="false"
          style="width: 97%; margin: 5px"
        >
          <myEchartsVue />
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card
          title="APP平台用户发送排行榜"
          :bordered="false"
          style="width: 95%; margin: 5px"
        >
          <p>系统管理员：12345</p>
          <p>用户一：10345</p>
          <p>用户二：9345</p>
          <p>用户三：8345</p>
          <p>用户四：7345</p>
          <p>用户五：6345</p>
          <p>用户六：5345</p>
          <p>用户七：4345</p>
        </a-card>
      </a-col>
    </a-row>
  </div>
  <!-- <img src="@/assets/images/dashboard.png" alt="" /> -->
</template>

<script lang='ts'>
import { reactive, toRefs, onBeforeMount, onMounted } from "vue";
import myEchartsVue from "./components/myEcharts.vue";
export default {
  name: "Dashboard",
  components: {
    myEchartsVue,
  },

  setup() {
    //console.log('1-开始创建组件-setup')
    const data = reactive({
      messageCountInfo: {},
      emailCountInfo: {},
    });
    onBeforeMount(() => {
      //console.log('2.组件挂载页面之前执行----onBeforeMount')
    });
    onMounted(() => {
      //console.log('3.-组件挂载到页面之后执行-------onMounted')
    });
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
};
</script>
<style lang='scss' scoped>
</style>